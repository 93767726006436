import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Results.css";
import Footer from "../Components/LexoFooter";
import Header from "../Components/Header/Header";
import SearchResultsComponent from "../Components/SearchRes";

interface SearchResultData {
  organic: string[];
  sponsored: string[];
  products: string[];
  related: string[];
}

interface Props {}

export const Results: React.FC<Props> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";

  const [results] = useState<SearchResultData>({
    organic: [],
    sponsored: [],
    products: [],
    related: [],
  });
  const [query] = useState(queryParam);

  const handleSearch = async () => {
    // try {
    //   if (!query) return;

    //   const url = queryParams.get("postback")
    //     ? String(queryParams.get("postback"))
    //     : nextToken
    //     ? `https://api.example.com/feed?token=&organic=5&enableProducts=1&q=${query}&pageToken=${nextToken}`
    //     : `https://api.example.com/feed?token=&organic=5&enableProducts=1&q=${query}`;

    //   const response = await fetch(url);
    //   if (response.ok) {
    //     const data = await response.json();

    //     setResults((prevResults) => {
    //       if (prevResults.organic.length === 0) {
    //         return {
    //           organic: [...data.organic],
    //           sponsored: [...data.sponsored],
    //           products: [...data.products],
    //           related: [...data.related],
    //         };
    //       }
    //       return {
    //         organic: [...prevResults.organic, ...data.organic],
    //         sponsored: [...prevResults.sponsored],
    //         products: [...prevResults.products],
    //         related: [...prevResults.related],
    //       };
    //     });
    //     setNextToken(
    //       data.props && data.props.nextPageToken
    //         ? data.props.nextPageToken
    //         : null
    //     );
    //   } else {
    //     console.error("Error fetching data");
    //   }
    // } catch (error) {
    //   console.error("An error occurred:", error);
    // }

    const navEvent = new Event("navigation");
    window.dispatchEvent(navEvent);
  };

  const setRelatedValues = async (search: any) => {
    window.location.href = `/results?query=${search}`;
    handleSearch();
  };

  useEffect(() => {
    if (query !== undefined) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="results-container">
        <SearchResultsComponent
          results={results}
          setRelatedValues={setRelatedValues}
        />
      </div>
      <Footer />
    </>
  );
};
