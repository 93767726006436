import React from "react";
import "./LexoFooter.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="horizontal-scroll2"><div className="horizontal-scroll"></div></div>
      <br /> <br />
      <div className="footer-lexo-links">
        <a href="https://www.lexornet.com/about">About</a>
        <a href="https://www.lexornet.com/contact">Contact</a>
        <small><a href="/privacy-policy">Privacy Policy</a></small>
        <small><a href="/terms-of-use">Terms Of Use</a></small>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h6 className="lexo-h6" >Lexornet ltd. ©</h6>
      </div>
    </footer>
  );
};

export default Footer;
