import React, { useState, useEffect, useMemo } from "react";
import "../App.css";

interface SearchResultProps {
  results: {
    organic: any[];
    sponsored: any[];
    products: any[];
    related: any[];
  };
  setRelatedValues: any;
}

const SearchResult: React.FC<SearchResultProps> = ({
  results,
  setRelatedValues,
}) => {
  
  const [likedWebsites, setLikedWebsites] = useState<string[]>([]);
  const [dislikedWebsites, setDislikedWebsites] = useState<string[]>([]);

  useEffect(() => {
    const cachedLikedWebsites = localStorage.getItem('likedWebsites');
    const cachedDislikedWebsites = localStorage.getItem('dislikedWebsites');

    if (cachedLikedWebsites) {
      setLikedWebsites(JSON.parse(cachedLikedWebsites));
    }

    if (cachedDislikedWebsites) {
      setDislikedWebsites(JSON.parse(cachedDislikedWebsites));
    }
  }, []);
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const cltrk = urlParams.get('cltrk');

  useEffect(() => {
    localStorage.setItem('likedWebsites', JSON.stringify(likedWebsites));
    localStorage.setItem('dislikedWebsites', JSON.stringify(dislikedWebsites));
  }, [likedWebsites, dislikedWebsites]);

  useEffect(() => {

    const bodyScript = document.createElement("script");
    bodyScript.innerHTML = `
    function myCallback(ads) {
      console.log(ads);
    }
    YOUTextlink({
        cid: '${urlParams.get('cid')}',
        sid: '${urlParams.get('sid')}',
        market: '${urlParams.get('market')}',
        lang: '${urlParams.get('lang')}',
        ads_n: 20,
        query_parameter: 'query',
        lazyloading: true,
        sub_id: '${urlParams.get('sub_id')}',
        disabled_extensions: {
            action: true,
            callout: true,
            favicon: true,
            fourth_line_annotation: true,
            image: true,
            merchant_rating: true,
            review: true,
            sitelinks: true,
            smart_annotation: true
        },
        cltrk: '${cltrk || ''}'
    });
  `;
    document.body.appendChild(bodyScript);
  }, [urlParams, cltrk]);
  return (
    <div className="div-mob">
      {<div data-youads="ads"></div>}
    </div>
  );
};

export default SearchResult;