import React from "react";
import "../Header/LexoHeader.css";
import logo4Image from "../../img/lexo-high-resolution-logo-transparent.png";
import SearchComponent from "../LexoSearchHeader";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="thetop">
      <a href="/">
        <img src={logo4Image} alt="lexo" className="lexoLogo" />
      </a>
      <SearchComponent />
    </div>
  );
};
export default Header;
